'use client';
import { FC, useState } from 'react';
import styles from './EventsGrid.module.scss';
import { EventArrElement } from '@/models/EventModels';
import { useTranslations } from 'next-intl';
import { usePathname } from 'next/navigation';
import Arrow from '../../app/assets/icons/arrow-r.svg';
import Image from 'next/image';
import SingleEvent from '../../shared/SingleEvent';
interface EventsGridProps {
  className?: string;
  title: string;
  events: EventArrElement[];
  allLink?: string | null;
  tabs?: { title: string; slug: string }[];
}

const EventsGrid: FC<EventsGridProps> = ({ className = '', title, events, allLink, tabs }) => {
  const t = useTranslations('BURGER_MENU');
  const t_2 = useTranslations('ORGANIZATION_BLOCK');

  const location = usePathname();
  const [active, setActive] = useState(tabs?.[0] || { title: 'start', slug: 'start' });

  const handleSetActive = (item: { title: string; slug: string }) => {
    setActive(item);
  };

  return (
    <div className={`${styles.root} ${className} container`}>
      {/*<div className={`${styles.header} ${allLink && styles.header__arrow}`}>*/}
      {/*  <h2 className={`${styles.title} `}>{t(title)}</h2>{' '}*/}
      {/*  {allLink && <Image src={Arrow} alt="arrow" />}*/}
      {/*  {tabs && (*/}
      {/*    <div className={styles.tubs}>*/}
      {/*      {tabs.map((item, idx) => (*/}
      {/*        <button*/}
      {/*          onClick={() => handleSetActive(item)}*/}
      {/*          className={`${styles.tub} ${active.slug === item.slug && styles.tub__active}`}*/}
      {/*          key={idx}>*/}
      {/*          {t_2(item.title)}*/}
      {/*        </button>*/}
      {/*      ))}*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</div>*/}
      <div className={`${styles.events}`}>
        {events &&
          events.length > 0 &&
          events.map((event, idx) => <SingleEvent event={event} key={idx}></SingleEvent>)}
      </div>
    </div>
  );
};

export default EventsGrid;
