'use client';


import {useBackgroundImage} from "@/shared/BackgroundImageComponent/BackgroundImageProvider";
import {useEffect} from "react";

export default function EmptyBackgroundImage() {
  const { setImageUrl } = useBackgroundImage();

  useEffect(() => {
    const gradient = `linear-gradient(0deg, rgba(12,11,12,1) 0%, transparent 100%)`;
    setImageUrl(gradient);
  }, [setImageUrl]);

  return null;
}
