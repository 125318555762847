'use client';
import { FC, ReactNode } from 'react';
import styles from './MainContainer.module.scss';

interface MainContainerProps {
  className?: string;
  children?: ReactNode;
}

const MainContainer: FC<MainContainerProps> = ({ className = '', children }) => {
  return <div className={`${styles.root} ${className}`}>{children}</div>;
};

export default MainContainer;
