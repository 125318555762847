import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/gligle-app-front-next/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager" */ "/var/www/gligle-app-front-next/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/var/www/gligle-app-front-next/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/gligle-app-front-next/src/containers/MainContainer/MainContainer.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/gligle-app-front-next/src/shared/BackgroundImageComponent/EmptyBackgroundImage.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/gligle-app-front-next/src/shared/EventsGrid/EventsGrid.tsx");
