'use client';
import { FC, useEffect, useState } from 'react';
import styles from './SingleEvent.module.scss';
import { EventArrElement } from '@/models/EventModels';
import Image from 'next/image';
import Link from 'next/link';
import { mainPath } from '@/app/routes/MainPath';
import { format } from 'date-fns';
import {useTranslations} from "next-intl";
import VerificationPledge from "@/shared/VerificationPledge";

interface SingleEventProps {
  className?: string;
  event: EventArrElement;
  comeFrom?: string;
}

const SingleEvent: FC<SingleEventProps> = ({ className = '', event, comeFrom = '/' }) => {
  const [dateStart, setStartDate] = useState<string>('');
  const [monthStart, setStartMonth] = useState<string>('');
  const t = useTranslations('HOME_PAGE');

  const start_date = new Date(event.start);
  const end_date = new Date(event.end);

  const is_ongoing = start_date < new Date() && end_date > new Date();

  useEffect(() => {
    if (event) {
      setStartDate(format(new Date(event.start), 'dd'));
      setStartMonth(format(new Date(event.start), 'MMM'));
    }
  }, [event]);

  return (
    <Link
      prefetch={false}
      className={`${styles.root} ${className}`}
      href={`${mainPath.single_event.path}/${event.organization?.slug}/${event.id}`}>
      <div className={styles.header}>
        <Image
          className={styles.header__image}
          src={event.poster_image || ''}
          alt={event.title}
          style={{ objectFit: 'cover' }}
          width={517}
          height={310}
        />

        <div className={styles.header__data}>
          {/*<span>{event.address}</span>*/}
          {is_ongoing && <span className={styles.header__data__ongoing}>{t("ongoing")}</span>}
          <span>{t("from_price")} ₴{+event.price}</span>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.footer__date}>
          <b> {dateStart}</b>
          <span>{monthStart}</span>
        </div>
        <div className={styles.footer__concert}>
          <b>{event.title}</b>
          <div className={styles.footer__concert__organization}>
            <p>{event.organization.name}</p>
            {
              event.organization.is_verified && (
                  <VerificationPledge is_verified={event.organization.is_verified} only_icon={true}/>
                )
            }
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SingleEvent;
