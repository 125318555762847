export const mainPath = {
  popular_events: {
    path: `/popular-events`,
    slug: '/popular-events',
    name: 'Popular events',
  },
  upcoming_events: {
    path: `/upcoming-events`,
    slug: '/upcoming-events',
    name: 'Upcoming events',
  },
  single_event: {
    path: `/event`,
    slug: '/event',
    name: 'Events',
  },
  my_tickets: {
    path: `/my-tickets`,
    slug: '/my-tickets',
    name: 'My tickets',
  },
  buy_ticket: {
    path: `/buy-ticket`,
    slug: '/buy-ticket',
    name: 'Buy ticket',
  },
  organization: {
    path: `/organizations`,
    slug: '/organizations',
    name: 'Organizations',
  },
  main_page: {
    path: `/`,
    slug: '/',
    name: 'Main page',
  },
};
