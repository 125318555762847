'use client';
import React, { FC, useEffect, useState } from 'react';
import styles from './VerificationPledge.module.scss';
import Image from "next/image";
import Verified from "@/app/assets/icons/verified.svg";
import Unverified from "@/app/assets/icons/unverified.svg";
import {useTranslations} from "next-intl";


interface VerificationPledgeProps {
  className?: string;
  is_verified: boolean;
  only_icon?: boolean;
  is_big?: boolean;
}

const VerificationPledge: FC<VerificationPledgeProps> = ({
    className = '',
    is_verified,
    only_icon = false,
    is_big = false,
}) => {
  const t = useTranslations('ORGANIZATION_BLOCK');

  return (
      <div className={`${styles.root} ${is_verified ? styles.verified : styles.unverified} ${is_big && styles.big} ${className}`}>
        {
          is_verified ?
              (<Image src={Verified} alt="verified"/>):
              (<Image src={Unverified} alt="unverified"/>)
        }
        {
          !only_icon && (<p>{is_verified ? t("verified") : t("unverified")}</p>)
        }
      </div>
  );
};

export default VerificationPledge;
